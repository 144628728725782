import {
  useCreateLinkStyleMutation,
  useDeleteLinkTypeMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useLinkStyle(
  onChange: (values: string) => void,
  typeId: string,
): ReturnUseEntityType {
  const message = useStatusMessage();

  const [createLinkStyle, { loading: createLoading }] =
    useCreateLinkStyleMutation({
      onError: () => message.open('error'),
      refetchQueries: ['linkStyles'],
    });

  const [remove, { loading: removeLoading }] = useDeleteLinkTypeMutation({
    refetchQueries: ['linkStyles'],
  });

  const handleCreate = useCallback(
    async (name: string) => {
      await createLinkStyle({
        variables: {
          input: {
            name,
            productType: typeId,
            editable: true,
          },
        },
        onCompleted: async (data) => {
          if (data?.createLinkStyle?.data?.id) {
            onChange(data.createLinkStyle.data.id);
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [createLinkStyle, message, typeId, onChange],
  );
  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );
  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
