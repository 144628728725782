import { useInventoryAuditItemsQuery } from '@/graphql';
import { useDrawerFilter } from '@filters/hooks/useDrawerFilter';
import { useEntityTableState } from '@hooks/crm/useEntityTableState';
import { HandleTableChangeType } from '@inventory/inventoryAudit/inventoryAuditDetails/helpers/createTabsItems';
import { TableParams } from '@ui/table/types';
import { useEffect } from 'react';
import { useParams } from 'react-router';

interface InventoryAuditItemsReturnType {
  data: InventoryAuditItemsQuery | undefined;
  loading: boolean;
  handleTableChange: HandleTableChangeType;
  handleTotalChange: () => void;
  tableParams: TableParams;
  queryParams: {
    pagination: {
      page?: number | undefined;
      pageSize: number | undefined;
    };
    sort?: string | string[];
  };
  handleToggleFilterDrawer: () => void;
  filters: InventoryAuditFiltersInput;
}

export const useInventoryAuditItems = (): InventoryAuditItemsReturnType => {
  const params = useParams<{ uuid: string }>();

  const { handleToggleFilterDrawer, filters } =
    useDrawerFilter<InventoryAuditItemFiltersInput>({
      entityName: 'inventoryAuditDetails',
      withDefaultFilters: false,
    });

  const { tableParams, handleTableChange, queryParams, handleTotalChange } =
    useEntityTableState();

  const { data, loading, refetch } = useInventoryAuditItemsQuery({
    variables: {
      filters: {
        inventoryAudit: {
          uuid: { eq: params.uuid },
        },
        ...filters,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    handleTotalChange(data?.inventoryAuditItems?.meta?.pagination.total);
  }, [data?.inventoryAuditItems?.meta?.pagination.total, handleTotalChange]);

  return {
    data,
    loading,
    handleTableChange,
    handleTotalChange,
    tableParams,
    queryParams,
    handleToggleFilterDrawer,
    filters,
  };
};
