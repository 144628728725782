import { useBackingsLazyQuery } from '@/graphql';
import { useMeRole } from '@components/onboarding/hooks/useMeRole';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { useCustomSelectLoader } from '@inventory/inventoryManagement/products/hooks/useCustomSelectLoader';
import { useProductFormWatch } from '@inventory/inventoryManagement/products/hooks/useProductFormWatch';
import { useBacking } from '@inventory/inventoryManagement/products/inputs/BackingInput/useBacking';
import { SelectCustomOption } from '@inventory/inventoryManagement/products/inputs/SelectCustomOption';
import { useDataSelect } from '@inventory/inventoryManagement/products/inputs/useDataSelect';
import { AddNewSelectButton } from '@ui/form/AddNewSelectButton';
import { Skeleton } from 'antd';
import { SelectProps } from 'antd/lib';
import { FC, useEffect, useMemo } from 'react';

interface Props extends SelectProps {
  onChange?: (value: string | null) => void;
  initialValue?: string;
  editable?: boolean;
}

export const BackingInput: FC<Props> = ({
  onChange,
  initialValue,
  editable = true,
}) => {
  const { productType } = useProductFormWatch();
  const { handleChange, handleSearch, queryParams, selectedValue } =
    useDataSelect({
      onChange,
      initialSelectedValue: initialValue,
      initialFilters: {
        productType: {
          id: { eq: productType },
        },
      },
    });

  const { mutationLoading, handleCreate, handleRemove } = useBacking(
    handleChange,
    productType,
  );

  const [_, { data, refetch, loading }] = useBackingsLazyQuery({
    variables: {
      ...queryParams,
      pagination: {
        limit: -1,
      },
      sort: ['createAt:asc'],
    },
  });
  const { role } = useMeRole();
  const loader = useCustomSelectLoader(loading);

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const options = useMemo(() => {
    const types = data?.backings?.data || [];

    return types?.map((entity) => ({
      value: entity?.id,
      label: entity?.attributes?.name,
      editable: role === 'Owner' ? true : entity.attributes?.editable,
    }));
  }, [data?.backings?.data, role]);

  if (loader) {
    return <Skeleton.Input active={true} size={'default'} block={true} />;
  }

  return (
    <SearchSelect
      allowClear
      defaultValue={initialValue}
      placeholder={`Select backing ${editable ? 'or create a new one' : ''}`}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      optionRender={(option) => {
        return <SelectCustomOption {...option} onRemove={handleRemove} />;
      }}
      loading={loading || mutationLoading}
      onSearch={handleSearch}
      dropdownRender={(menu) => (
        <>
          {menu}
          {editable && <AddNewSelectButton handleAddNew={handleCreate} />}
        </>
      )}
    />
  );
};
