import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { BarcodeSelect } from '@components/reports/inventory/BarcodeSelect';
import { AdditionalFieldProps } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { SubLocationSelectInput } from '@inventory/inventoryManagement/products/inputs/SublocationSelect';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { Col, Row } from 'antd';

const customInputStyles = { margin: 0 };

export const InventoryProductsAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'barcode'}
      label={'Barcode'}
      defaultOpen={!!values?.barcode}
      withDivider
    >
      <BarcodeSelect
        placeholder={'Filter by Barcode'}
        initValue={values?.barcode ?? undefined}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      withDivider
      label={'Business Location'}
      defaultOpen={!!values?.locationIds || !!values?.sublocation}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <CustomFormItem
            label={'Business Location'}
            name={'locationIds'}
            style={customInputStyles}
          >
            <BusinessLocationsSelect
              placeholder={'Choose location'}
              storesOnly={false}
            />
          </CustomFormItem>
        </Col>
        <Col span={24}>
          <CustomFormItem
            label={'Sublocation'}
            name={'sublocation'}
            style={customInputStyles}
          >
            <SubLocationSelectInput
              businessLocationId={values?.locationIds}
              initialValue={values?.sublocation ?? undefined}
              placeholder={'Choose sublocation'}
              editable={false}
            />
          </CustomFormItem>
        </Col>
      </Row>
    </ExpandableFormItem>
  </>
);
