import { useCreateSubLocationMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useSubLocationData(businessLocationId: string): ReturnType {
  const message = useStatusMessage();

  const [createSubLocation, { loading: createLoading }] =
    useCreateSubLocationMutation({
      onError: () => message.open('error'),
      refetchQueries: ['subLocations'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      await createSubLocation({
        variables: {
          input: {
            name,
            businessLocation: businessLocationId,
          },
        },
        onCompleted: async (data) => {
          if (data?.createSublocation?.data?.id) {
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [businessLocationId, createSubLocation, message],
  );

  return {
    createLoading,
    handleCreate,
  };
}
