import { useCreateStrandMutation, useDeleteStrandMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useStrand(
  onChange: (values: string) => void,
  typeId: string,
): ReturnUseEntityType {
  const message = useStatusMessage();
  const [createStrand, { loading: createLoading }] = useCreateStrandMutation({
    onError: () => message.open('error'),
    refetchQueries: ['strands'],
  });
  const [remove, { loading: removeLoading }] = useDeleteStrandMutation({
    refetchQueries: ['timePeriods'],
  });

  const handleCreate = useCallback(
    async (name: string) => {
      await createStrand({
        variables: {
          input: {
            name,
            productType: typeId,
            editable: true,
          },
        },
        onCompleted: (data) => {
          if (data?.createStrand?.data?.id) {
            onChange(data.createStrand.data.id);
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [createStrand, message, onChange, typeId],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );

  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
