import {
  useCreateMetalTypeMutation,
  useDeleteMetalTypeMutation,
  useMetalTypesQuery,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useMetalType(
  onChange: (values: string) => void,
): ReturnUseEntityType {
  const message = useStatusMessage();

  const { data } = useMetalTypesQuery({
    variables: {
      pagination: {
        limit: -1,
      },
    },
  });

  const metalTypesArr = data?.metalTypes?.data?.map(
    (item) => item.attributes?.name,
  );

  const [createMetalType, { loading: createLoading }] =
    useCreateMetalTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['metalTypes'],
    });

  const [remove, { loading: removeLoading }] = useDeleteMetalTypeMutation({
    refetchQueries: ['metalTypes'],
  });

  const handleCreate = useCallback(
    async (name: string) => {
      if (metalTypesArr?.includes(name)) {
        message.open('error', 'This name already exist');
        return;
      }

      await createMetalType({
        variables: {
          input: {
            name,
            editable: true,
          },
        },
        onCompleted: (data) => {
          if (data?.createMetalType?.data?.id) {
            onChange(data.createMetalType.data.id);
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [createMetalType, message, metalTypesArr, onChange],
  );
  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );
  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
