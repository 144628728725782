import { AnyObject } from '@helpers/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Filter = {
  barcode?: InputMaybe<StringFilterInput>;
};

interface Props<T extends AnyObject = AnyObject> {
  onChange?: (value: string) => void;
  initialSelectedValue?: Maybe<string>;
}

export const useInventoryProductByBarcode = <T extends AnyObject = AnyObject>({
  onChange,
  initialSelectedValue,
}: Props<T>) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(
    initialSelectedValue || null,
  );
  const [filters, setFilters] = useState<Filter>({});

  const handleChange = useCallback(
    (value: string) => {
      setSelectedValue(value);

      onChange?.(value);
    },
    [onChange],
  );

  const queryParams = useMemo(
    () => ({
      filters,
      sort: ['createdAt:desc'],
    }),
    [filters],
  );

  const handleSearch = (value: string) => {
    setFilters({
      barcode: {
        containsi: value,
      },
    });
  };

  useEffect(() => {
    if (initialSelectedValue) {
      setSelectedValue(initialSelectedValue);
    }
  }, [initialSelectedValue]);

  return {
    selectedValue,
    handleChange,
    handleSearch,
    queryParams,
  };
};
