import { FC, useEffect, useMemo } from 'react';

import { SelectProps } from 'antd/lib';

import { useProductsQuery } from '@/graphql';

import { useInventoryProductByBarcode } from '@components/reports/inventory/useInventoryProductByBarcode';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initValue?: string;
}

export const BarcodeSelect: FC<Props> = ({
  onChange,
  initValue,
  value,
  ...props
}) => {
  const { handleChange, handleSearch, queryParams, selectedValue } =
    useInventoryProductByBarcode({
      onChange,
      initialSelectedValue: initValue,
    });

  const { data, refetch, loading } = useProductsQuery({
    variables: { ...queryParams, pagination: { limit: 5 } },
  });
  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const options = useMemo(() => {
    const ordersData = data?.products?.data || [];

    return ordersData?.map((product) => ({
      value: product?.id,
      label: product?.attributes?.barcode,
    }));
  }, [data?.products?.data]);

  return (
    <SearchSelect
      value={selectedValue}
      defaultValue={initValue}
      placeholder={'Select the barcode'}
      onChange={handleChange}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      {...props}
    />
  );
};
