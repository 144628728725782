import {
  useCreateConditionTypeMutation,
  useDeleteConditionTypeMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useCondition(
  onChange: (values: string) => void,
): ReturnUseEntityType {
  const message = useStatusMessage();
  const [createCondition, { loading: createLoading }] =
    useCreateConditionTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['conditionTypes'],
    });
  const [remove, { loading: removeLoading }] = useDeleteConditionTypeMutation({
    refetchQueries: ['conditionTypes'],
  });

  const handleCreate = useCallback(
    async (name: string) => {
      await createCondition({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createConditionType?.data?.id) {
            onChange(data.createConditionType.data.id);
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [createCondition, message, onChange],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );
  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
