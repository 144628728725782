import {
  useCreateStrandsLengthMutation,
  useDeleteTimePeriodMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useStrandsLength(
  onChange: (values: string) => void,
  typeId: string,
): ReturnUseEntityType {
  const message = useStatusMessage();
  const [createStrandsLength, { loading: createLoading }] =
    useCreateStrandsLengthMutation({
      onError: () => message.open('error'),
      refetchQueries: ['strandsLengths'],
    });
  const [remove, { loading: removeLoading }] = useDeleteTimePeriodMutation({
    refetchQueries: ['strandsLengths'],
  });
  const handleCreate = useCallback(
    async (name: string) => {
      await createStrandsLength({
        variables: {
          input: {
            name,
            productType: typeId,
            editable: true,
          },
        },
        onCompleted: (data) => {
          if (data?.createStrandsLength?.data?.id) {
            onChange(data.createStrandsLength.data.id);
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [createStrandsLength, message, onChange, typeId],
  );
  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );
  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
