import { FilterFunction, LocationSublocationFilterInput } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import dayjs from 'dayjs';

export const createProductBarcodeFilter: FilterFunction<string> = (
  barcode?: string,
): ProductFiltersInput => {
  return barcode
    ? {
        id: {
          eq: barcode,
        },
      }
    : {};
};

export const createInventoryProductCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ProductFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};

export const createBusinessLocationProductFilter: FilterFunction<
  LocationSublocationFilterInput
> = ({ locationIds, sublocation } = {}): ProductFiltersInput => {
  return locationIds?.length !== 0 || sublocation
    ? ({
        productInventoryItems: {
          businessLocation: {
            id: {
              in: locationIds,
            },
          },
          sublocation: {
            id: {
              eq: sublocation,
            },
          },
        },
      } as ProductFiltersInput)
    : {};
};
