import {
  useCreateManufacturingProcessMutation,
  useDeleteManufacturingProcessMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useManufacturingProcess(
  onChange: (values: string) => void,
): ReturnUseEntityType {
  const message = useStatusMessage();
  const [createManufacturing, { loading: createLoading }] =
    useCreateManufacturingProcessMutation({
      onError: () => message.open('error'),
      refetchQueries: ['manufacturingProcess'],
    });
  const [remove, { loading: removeLoading }] =
    useDeleteManufacturingProcessMutation({
      refetchQueries: ['manufacturingProcess'],
    });
  const handleCreate = useCallback(
    async (name: string) => {
      await createManufacturing({
        variables: {
          input: {
            name,
            editable: true,
          },
        },
        onCompleted: (data) => {
          if (data?.createManufacturingProcess?.data?.id) {
            onChange(data.createManufacturingProcess.data.id);
            message.open('success', 'Option added successfully');
          }
        },
      });
    },
    [createManufacturing, message, onChange],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );

  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
